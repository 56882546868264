import { FirebaseOptions } from "firebase/app";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyBap4Xolss_OOf9YSOy6JnqG5InbQ67Ge0",
  authDomain: "kidletcareauth.firebaseapp.com",
  databaseURL: "https://kidletcareauth.firebaseio.com",
  projectId: "kidletcareauth",
  storageBucket: "kidletcareauth.appspot.com",
  messagingSenderId: "175767251610",
  appId: "1:175767251610:web:dadf9e4f26dd0f22ba0d23",
  measurementId: "G-GV31VMD386"
};

export default firebaseConfig;